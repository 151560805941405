// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAfDMTLNwW73MnX-wu7bOxhrW8Uzx9rkoo',
    authDomain: 'mumu-care.maria.org.tw',
    databaseURL: 'https://mumu-care.firebaseio.com',
    projectId: 'mumu-care',
    storageBucket: 'mumu-care.appspot.com',
    messagingSenderId: '882159654999',
    appId: '1:882159654999:web:97eb8d2690097ea73aab12',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
